.star_country__icon{
    color: #ffc107
}


.star_country__icon_clicked{
    color: white
}



.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
/*     padding-top: 10px;
    padding-bottom: 10px; */

}