/* .css-ykovpn-MuiGrid-root{
    display: flex !important;
    align-items: center;
} */

.linear-progress-pc span{
    background-color: #FFC108 !important
}

.pay_button_instruction:hover{
    background: white !important;
}

.update_free_button:hover{
    background: #5e35b1 !important;
}