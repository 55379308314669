.rrr{
    margin-left: -16px !important;
    margin-right: -16px !important;
    background-color: none !important;
}

.rrr_{
    padding: 0px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
    padding-top: 16px  !important;
    background-color: none !important;
}

.rrr_ .MuiButtonBase-root {
    background-color: transparent !important;
}



.pre_ {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    animation: example 2s linear 0s infinite alternate;
  }
  
  @keyframes example {
    from {
      width: 0;
    }
    to {
      width: 90px;
    }
  }