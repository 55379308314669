.instruction_block {
    max-width: 100% !important;
    margin: 0px !important;
}

/* .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
   
    overflow: hidden !important;
} */

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
}


@media (min-width:320px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 100%;
        position: relative;
        height: 100vh;
        overflow: hidden !important; 
      }
}

@media (min-width:481px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        height: 100vh;
        overflow: hidden !important; 
      }
}

@media (min-width:641px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        height: 50vh;
        overflow: hidden !important; 
      }
}

@media (min-width:961px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        height: 50vh;
        overflow: hidden !important; 
      }
}

@media (min-width:1025px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        height: 50vh;
        overflow: hidden !important; 
      }
}

@media (min-width:1281px) {
    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
         height: 50vh;
        overflow: hidden !important;
    }
}