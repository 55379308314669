
.accordion_test {
    padding: 0px !important;
}

.accordion_test .MuiAccordionSummary-content{
    margin: 5px !important;
}
.accordion_test .css-15v22id-MuiAccordionDetails-root
{
    padding-top: 2px !important;
}
/* .MuiLoadingButton-loadingIndicator {
    color: #FFF  !important;
  } */

._link_box {
    background: red;
}


/* .css-zkgv5p-MuiLinearProgress-bar1 {

    background-color: #FFC108 !important
} */