.css-jvzmdc {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
    border-radius: 8px;
    overflow: hidden;
    background:#5e35b1;
    position: relative;
    color: rgb(255, 255, 255);
    
}




.css-jvzmdc2 {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 8px;
    overflow: hidden;
    background:rgb(82, 196, 26);
    position: relative;
    color: rgb(255, 255, 255);
}
.css-1y3mnrk2 {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 1px rgb(82, 196, 26) !important;
}

.css-1m9128y {
    z-index: 1;
    width: 100%;
    margin-top: -45px !important;
    text-align: center;
    position: absolute;
    right: 0px;
}


.css-18mhetb:last-child {
 /*    padding-bottom: 24px; */
}

.css-18mhetb {
    padding: 10px;
}

.css-1iakqhe {
    position: absolute;

    left: 6px;
    bottom: 5px;
    color: rgb(255, 255, 255);
    transform: rotate(25deg);
}

.css-mgf9hi {
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    margin-top: -8px;
    width: calc(100% + 8px);
    margin-left: -8px;
    justify-content: center;
    align-items: center;
}

.css-1iakqhe > svg {
    width: 100px;
    height: 100px;
    opacity: 0.35;
}


.css-6flbmm {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 2.1875rem;
}



.css-1y3mnrk {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 1px #5e35b1 ;
  
}

.css-1f8sh1y{
   padding-bottom: 15px;
}

.css-1y3mnrk1 {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 1px rgb(33, 150, 243) !important;
}

.css-1qm1lh {
}

.css-jvzmdc1 {
    
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 8px;
    overflow: hidden;
    background:rgb(33, 150, 243)	;
    position: relative;
    color: white
}


.css-mgf9hi1 {
    box-sizing: border-box;
    display: flex;
    flex-flow: column wrap;
    margin-top: -8px;
    width: calc(100% + 8px);
    margin-left: -8px;
    justify-content: center;
    align-items: center;
}

.css-1iakqhe1 {
    position: absolute;

    left: 6px;
    bottom: 5px;
    color: rgb(255, 255, 255);
    transform: rotate(25deg);
}


.css-6flbmm1 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.10, 1) 0ms;
    font-size: 2.1875rem;
}



.css-1iakqhe2 {
    margin: 0px;
    letter-spacing: 0em;
    font-weight: 400;
    line-height: 1.5em;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    color: rgb(255, 255, 255);
    position: absolute;
    right: 76px;
    top: 14px;
    transform: rotate(-10deg);
}


.css-6flbmm2 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.10, 1) 0ms;
    font-size: 2.1875rem;
}






.css-1iakqhe3::before {
    position: absolute;

  /*   left: 90%;
    bottom: -4px; */
    color: rgb(255, 255, 255);
    transform: rotate(-10deg);
}


.css-6flbmm3 {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.10, 1) 0ms;
    font-size: 2.1875rem;
}




/**/
.css-1y3mnrk4 {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 1px #A6242E !important;
}

.css-jvzmdc4 {
    
    z-index: 2;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 8px;
    overflow: hidden;
    background: #A6242E;

    position: relative;
    color: white

}


.css-1iakqhe4 {
    position: absolute;
    left: 6px;
    bottom: 40px;
    color: rgb(255, 255, 255);
    transform: rotate(25deg);
}
.css-1iakqhe5 {
    position: absolute;
    left: 227px;
    bottom: 40px;
    color: rgb(255, 255, 255);
    transform: rotate(25deg);
}
.css-1iakqhe6 {
    position: absolute;
    left: 111px;
    bottom: -31px;
    color: rgb(255, 255, 255);
    transform: rotate(25deg);
}



.css-jvzmdc4 {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 8px;
    overflow: hidden;
    background:#ffdf07;
    position: relative;
    color:black;
}
.css-1y3mnrk4 {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 1px #ffdf07 !important;
}